<template>
  <div class="sp_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/banner_sup.jpg" />
      </div>
    </div>
    <div class="container content">
      <div class="title">
        Here’s where you’ll find all the useful information and faqs to help
        with entering the Awards, booking your Festival pass and planning your
        trip. If you don’t find the answer to your question here, please get in
        touch and we’ll be pleased to answer your questions.
      </div>
      <div class="itembox">
        <div class="item" @click="toDetail('awards')">
          <div class="name">
            Awards Support<img src="@/assets/images/arrow.png" />
          </div>
          <div class="text">
            Please click here to contact us if you need any support.
          </div>
        </div>
        <div class="item" @click="toDetail('festival')">
          <div class="name">
            Festival Support<img src="@/assets/images/arrow.png" />
          </div>
          <div class="text">
            Please click here to contact us if you need any support.
          </div>
        </div>
        <div class="item" @click="toDetail('academy')">
          <div class="name">
            Academy Support<img src="@/assets/images/arrow.png" />
          </div>
          <div class="text">
            Please click here to contact us if you need any support.
          </div>
        </div>
        <div class="item" @click="toDetail('media')">
          <div class="name">
            Media Support<img src="@/assets/images/arrow.png" />
          </div>
          <div class="text">
            Please click here to contact us if you need any support.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Support',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    toDetail(cat) {
      this.$router.push({
        name: 'FooterSupportDetail',
        query: {
          cat,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sp_box {
  .title {
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }
  .itembox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    .item {
      width: 42%;
      margin-bottom: 20px;
      border: 1px solid #bfbfbf;
      padding: 3%;
      background-color: #f4f6f8;
      padding-bottom: 100px;
      cursor: pointer;
      line-height: 24px;
      .name {
        font-size: 24px;
        margin-bottom: 20px;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0px;
          width: 15px;
          display: block;
        }
      }
      .text {
        font-size: 18px;
      }
    }
    .item:hover {
      background-color: #a2d6f4;
    }
  }
}
@media (max-width: 1000px) {
}
</style>
